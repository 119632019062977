import createEnum from './createEnum.js'
export const PaymentWayEnum = createEnum([
  {
    label: '微信支付',
    value: 1,
  },
  {
    label: '现金',
    value: 10,
  },
  {
    label: '微信转账',
    value: 11,
  },
  {
    label: '支付宝转账',
    value: 12,
  },
  {
    label: '银联转账',
    value: 13,
  },
  {
    label: '刷卡支付',
    value: 14,
  },
  {
    label: '其他',
    value: 99,
  },
])
export const PaymentMethodList = createEnum([
  {
    label: '微信支付',
    value: 'WECHAT',
  },
  {
    label: '余额支付',
    value: 'BALANCE',
  },
])
