/**
 * 公共curd 方法封装
 * @author iron.guo 郭成杰
 * @since 2022-07-23
 * data中url定义 list为查询列表  delete为删除单条记录  deleteBatch为批量删除
 * getAction, deleteAction, putAction, postAction 请求方法封装
 */
import { postAction } from '@/api/common/manage'

export const BasePostMixin = {
  data() {
    return {
      /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
      queryParam: {
        pageNumber: 1,
        pageSize: 20,
      },
      editParam: {},
      /* 数据源 */
      dataSource: [],
      /* 数据条数 */
      totalCount: 0,
      /* table加载状态 */
      loading: false,
      /* table选中keys*/
      selectedRowKeys: [],
      /* table选中records*/
      selectionRows: [],
    }
  },
  created() {
    if (!this.disableMixinCreated) {
      // console.log(' -- iron.guo mixin created -- ')
      this.loadData()
      //初始化字典配置 在自己页面定义
      //this.initDictConfig();
    }
  },
  computed: {},
  methods: {
    loadData(arg) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      // console.log('arg:', arg)
      if (arg === 1) {
        this.queryParam.pageNumber = 1
        this.queryParam.pageSize = 20
      }
      console.log('this.queryParam:', this.queryParam, this.url.list)
      var params = this.queryParam //查询条件
      this.loading = true
      postAction(this.url.list, params)
        .then((res) => {
          if (res.code === 0) {
            const { records, total } = res.data
            this.dataSource = records
            this.totalCount = +total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    initDictConfig() {},

    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectionRows = selectionRows
    },
    onClearSelected() {
      this.selectedRowKeys = []
      this.selectionRows = []
    },
    searchQuery() {
      this.loadData(1)
      // 点击查询清空列表选中行
      this.selectedRowKeys = []
      this.selectionRows = []
    },
    superQuery() {
      this.$refs.superQueryModal.show()
    },
    searchReset(val) {
      for (let key in val) {
        if (key === 'daterange') {
          this[val[key]] = null
        }
      }
      this.queryParam = {}
      this.loadData(1)
    },
    batchDel: function () {
      if (!this.url.deleteBatch) {
        this.$message.error('请设置url.deleteBatch属性!')
        return
      }
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning('请选择一条记录！')
        return
      } else {
        //TODO SomeThings
        var ids = ''
        for (var a = 0; a < this.selectedRowKeys.length; a++) {
          ids += this.selectedRowKeys[a].id + ','
        }
        console.log('ids', ids)
        var that = this
        this.$confirm('是否删除选中数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            postAction(that.url.deleteBatch, { ids: ids }).then((res) => {
              console.log('res', res)
              if (res.code === 0) {
                that.$message.success('删除成功！')
                that.loadData()
              } else {
                that.$message.warning('删除失败！')
              }
            })
          })
          .finally(() => {
            that.loading = false
          })
      }
    },
    handleDelete: function (id) {
      if (!this.url.delete) {
        this.$message.error('请设置url.delete属性!')
        return
      }
      console.log('id>>', id)

      var that = this

      this.$confirm('是否删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          postAction(that.url.delete, { id: id }).then((res) => {
            if (res.code === 0) {
              that.$message.success('删除成功！')
              that.loadData()
            } else {
              that.$message.warning('删除失败！')
            }
          })
        })
        .finally(() => {
          that.loading = false
        })
    },
    handleEdit: function (record) {
      console.log('编辑开启', record)
      if (!this.url.handleEditUrl) {
        this.$message.error('请设置url.handleEditUrl属性!')
        return
      }
      var params = this.editParam //查询条件
      this.loading = true
      postAction(this.url.handleEditUrl, params)
        .then((res) => {
          if (res.code === 0) {
            this.loadData(1)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handEdit: function (record) {
      console.log('编辑开启', record)
      if (!this.url.handleEditUrl) {
        this.$message.error('请设置url.handleEditUrl属性!')
        return
      }
      this.loading = true
      postAction(this.url.handleEditUrl, record)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('编辑成功！')
            this.loadData(1)
          } else {
            this.$message.error('编辑失败！')
          }
        })
        .finally(() => {
          this.dailog.visible = false
          this.loading = false
        })
    },

    handleAdd: function () {
      console.log('新增开启', this.editParam)
      if (!this.url.add) {
        this.$message.error('请设置url.add属性!')
        return
      }
      var params = this.editParam //查询条件
      this.loading = true
      postAction(this.url.add, params)
        .then((res) => {
          if (res.code === 0) {
            this.loadData(1)
            this.$message.success('新增成功！')
          } else {
            this.$message.error('新增失败！')
          }
        })
        .finally(() => {
          this.dailog.visible = false
          this.loading = false
        })
    },
    modalFormOk() {
      // 新增/修改 成功时，重载列表
      this.loadData()
      //清空列表选中
      this.onClearSelected()
    },
    handleDetail: function (record) {
      console.log('查看详情', record)
    },
  },
}
