// 枚举类
export default function createEnum(enumList) {
  const values = []
  const labels = []
  const toObject = {}
  enumList.forEach((item) => {
    // console.log(item)
    toObject[item.value] = item
    values.push(item.value)
    labels.push(item.label)
  })
  return {
    values,
    labels,
    enum: enumList,
    dict: toObject,
    getLabelByValue(value) {
      return labels[values.indexOf(value)]
    },
    getValueByLabel(value) {
      return values[labels.indexOf(value)]
    },
  }
}
