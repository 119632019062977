import { getToken, setToken } from '@/utils/auth.js'
import { login1 } from '@/api/login'
// import { login } from '@/api/user'

const state = () => ({
  token: getToken(),
  hasToken: '',
})
const getters = {
  token: (state) => state.token,
  hasToken: (state) => state.token,
}
const mutations = {
  // 设置token
  setToken(state, token) {
    // console.log(token)
    state.token = token
    state.hasToken = token
    setToken(token)
  },
}
const actions = {
  login({ commit }, data) {
    // console.log(commit, data)
    return new Promise((resolve, reject) => {
      login1(data)
        .then((response) => {
          // console.log(response)
          if (response.code === 0) {
            // console.log(response)
            commit('setToken', response.data.accessToken)
            setToken(response.data.accessToken) // 登录成功后把token给到Cookies
            resolve()
          } else {
            reject(response.msg)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default { state, getters, mutations, actions }
