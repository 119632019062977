/**
 * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/vab/layouts'
import { publicPath, routerMode } from '@/config'

Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/center',
    component: () => import('@/views/login'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    meta: {
      hidden: true,
    },
  },
  {
    path: '/callback',
    component: () => import('@/views/callback'),
    meta: {
      hidden: true,
    },
  },
  // {
  //   path: '/403',
  //   name: '403',
  //   component: () => import('@/views/403'),
  //   meta: {
  //     hidden: true,
  //   },
  // },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: () => import('@/views/404'),
  //   meta: {
  //     hidden: true,
  //   },
  // },
  {
    path: '/welcome',
    name: 'Welcome',
    redirect: '/welcome/index',
    component: Layout,
    meta: {
      title: '欢迎页',
      icon: 'home-2-line',
      // levelHidden: true,
      hidden: true,
      guard: ['Admin'],
    },
    children: [
      {
        path: '/welcome/index',
        name: 'WelCome',
        component: () => import('@/views/welcome/index.vue'),
        meta: {
          title: '欢迎页',
          icon: 'home-2-line',
          noClosable: false, // 禁止关闭tab
          noColumn: true,
          // badge: 'Hot', // 小红点消息
        },
      },
    ],
  },
  // {
  //   path: '/admin/house/contract/detail',
  //   name: 'ContractDetail1',
  //   component: Layout,
  //   meta: {
  //     title: '合同详情',
  //     icon: 'home-2-line',
  //     hidden: true,
  //     guard: ['Admin'],
  //   },
  //   children: [
  //     {
  //       path: '/admin/house/contract/detail',
  //       name: 'ContractDetail2',
  //       component: () => import('@/views/build/contractDetail.vue'),
  //       meta: {
  //         title: '合同详情',
  //         icon: 'home-2-line',
  //         noClosable: false, // 禁止关闭tab
  //         noColumn: true,
  //       },
  //     },
  //   ],
  // },
  {
    path: '/403',
    name: '403',
    redirect: '/403',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: '/403',
        name: '403无权限',
        component: () => import('@/views/403'),
        meta: {
          title: '403',
          icon: 'home-2-line',
          // noClosable: false, // 禁止关闭tab
          noColumn: true,
          // badge: 'Hot', // 小红点消息
        },
      },
    ],
  },
  {
    path: '/404',
    name: '404无权限',
    redirect: '/404',
    component: Layout,
    meta: {
      hidden: true,
    },
    children: [
      {
        path: '/404',
        name: '',
        component: () => import('@/views/404'),
        meta: {
          title: '404',
          icon: 'home-2-line',
          // noClosable: false, // 禁止关闭tab
          noColumn: true,
          // badge: 'Hot', // 小红点消息
        },
      },
    ],
  },
]

export const asyncRoutes = [
  {
    id: '11',
    path: '/admin/home',
    name: 'Home',
    redirect: '/admin/dashboard',
    component: Layout,
    sort: 1,
    meta: {
      title: '数据预览',
      icon: 'home-2-line',
      // levelHidden: true,
      guard: ['Admin'],
    },
    children: [
      {
        id: '1547903898600214528',
        path: '/admin/dashboard',
        name: 'Index',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '数据预览',
          icon: 'home-2-line',
          noClosable: false, // 禁止关闭tab
          noColumn: true,
          // badge: 'Hot', // 小红点消息
        },
      },
    ],
  },
  {
    id: '1',
    path: '/sys',
    name: 'System',
    component: Layout,
    sort: 2,
    meta: {
      title: '系统管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/sys/user',
        name: 'User',
        component: () => import('@/views/system/user.vue'),
        meta: {
          title: '用户管理',
          icon: 'shield-user-line',
        },
      },
      {
        path: '/sys/role',
        name: 'Role',
        component: () => import('@/views/system/role.vue'),
        meta: {
          title: '角色管理',
          icon: 'dashboard-line',
        },
      },
      {
        path: '/sys/menu',
        name: 'Menu',
        component: () => import('@/views/system/menu.vue'),
        meta: {
          title: '菜单管理',
          icon: 'menu-line',
        },
      },
      {
        path: '/sys/dict',
        name: 'Dic',
        component: () => import('@/views/system/dic.vue'),
        meta: {
          title: '字典管理',
          icon: 'book-open-line',
        },
      },
      {
        path: '/sys/config',
        name: 'Setting',
        component: () => import('@/views/system/config.vue'),
        meta: {
          title: '系统参数配置',
          icon: 'settings-5-line',
        },
      },
    ],
  },
  {
    path: '/admin/house',
    name: 'Build',
    component: Layout,
    sort: 2,
    meta: {
      title: '房源',
      icon: 'building-4-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/house/info',
        name: 'Info',
        component: () => import('@/views/build/info.vue'),
        meta: {
          title: '房源信息',
          icon: 'building-line',
        },
      },
      {
        path: '/admin/house/building',
        name: 'Building',
        component: () => import('@/views/build/building.vue'),
        meta: {
          title: '楼栋详情',
          icon: 'building-2-line',
        },
      },
      {
        path: '/admin/house/community',
        name: 'Community',
        component: () => import('@/views/build/community.vue'),
        meta: {
          title: '楼栋小区',
          icon: 'building-4-line',
        },
      },
      {
        path: '/admin/house/contract',
        name: 'Contract',
        component: () => import('@/views/build/contract.vue'),
        meta: {
          title: '合同信息',
          icon: 'newspaper-line',
        },
      },
      {
        path: '/admin/house/contract/detail',
        name: 'ContractDetail',
        component: () => import('@/views/build/contractDetail.vue'),
        meta: {
          hidden: true,
          activeMenu: '/admin/house/contract',
          title: '合同详情',
          icon: 'newspaper-line',
          noClosable: false,
        },
      },
      {
        path: '/admin/house/meter',
        name: 'Meter',
        component: () => import('@/views/build/meter.vue'),
        meta: {
          title: '水电抄表',
          icon: 'database-2-line',
        },
      },
      {
        path: '/admin/house/meter/reading',
        name: 'MeterReading',
        component: () => import('@/views/build/meterReading.vue'),
        meta: {
          title: '智能水电表读数',
          icon: 'database-2-line',
        },
      },
      {
        path: '/admin/house/square',
        name: 'HouseSquare',
        component: () => import('@/views/build/houseSquare.vue'),
        meta: {
          title: '租房广场',
          icon: 'database-2-line',
        },
      },
      {
        path: '/admin/house/community/management',
        name: 'communityManagement',
        component: () => import('@/views/build/communityManagement.vue'),
        meta: {
          title: '小区管理',
          icon: 'database-2-line',
        },
      },
      {
        path: '/admin/house/smartdoorlock/management',
        name: 'smartdoorlockManagement',
        component: () => import('@/views/build/smartdoorlock/index.vue'),
        meta: {
          title: '智能门锁管理',
          icon: 'database-2-line',
        },
      },
    ],
  },
  {
    path: '/admin/merchant',
    name: 'Merchant',
    component: Layout,
    sort: 3,
    meta: {
      title: '商户管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/member/sms/management',
        name: 'SmsManagement',
        component: () => import('@/views/merchant/smsManagement.vue'),
        meta: {
          title: '商户短信管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/member/sms/buy',
        name: 'SmsBuy',
        component: () => import('@/views/merchant/smsBuy.vue'),
        meta: {
          title: '短信购买记录',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/member/sms/combo/management',
        name: 'SmsComboManagement',
        component: () => import('@/views/merchant/smsComboManagement.vue'),
        meta: {
          title: '商户短信套餐管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/member/sms/stencil/management',
        name: 'SmsStencilManagement',
        component: () => import('@/views/merchant/smsStencilManagement.vue'),
        meta: {
          title: '短信模版管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/member/sms/sending/record',
        name: 'SmsSendingRecord',
        component: () => import('@/views/merchant/smsSendingRecord.vue'),
        meta: {
          title: '短信发送记录',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/merchant/vip',
        name: 'Vip',
        component: () => import('@/views/merchant/vip.vue'),
        meta: {
          title: '商户会员绑定信息',
          hidden: true,
          icon: 'home-2-line',
        },
      },

      {
        path: '/admin/merchant/payRecord',
        name: 'PayRecord',
        component: () => import('@/views/merchant/payRecord.vue'),
        meta: {
          title: '商户钱包消费记录',
          icon: 'home-2-line',
        },
      },
      // {
      //   path: '/admin/merchant/cost',
      //   name: 'cost',
      //   component: () => import('@/views/merchant/cost.vue'),
      //   meta: {
      //     title: '商户成本费用配置',
      //     icon: 'home-2-line',
      //   },
      // },
      // {
      //   path: '/admin/merchant/rate',
      //   name: 'rate',
      //   component: () => import('@/views/merchant/rate.vue'),
      //   meta: {
      //     title: '商户业务费率设置',
      //     icon: 'home-2-line',
      //   },
      // },
      {
        path: '/admin/merchant/recharge',
        name: 'Recharge',
        component: () => import('@/views/merchant/recharge.vue'),
        meta: {
          title: '商户充值订单',
          icon: 'home-2-line',
        },
      },
      // {
      //   path: '/admin/merchant/billing',
      //   name: 'Billing',
      //   component: () => import('@/views/merchant/billing.vue'),
      //   meta: {
      //     title: '商户开票记录',
      //     icon: 'home-2-line',
      //   },
      // },
      {
        path: '/merchant/device',
        name: 'DeviceMerchant',
        component: () => import('@/views/device/merchant/index.vue'),
        meta: {
          title: '商户设备绑定管理',
          icon: 'album-line',
        },
      },
      {
        path: '/merchant/device/manage',
        name: 'DeviceMerchantManage',
        component: () => import('@/views/device/merchant/manage.vue'),
        meta: {
          hidden: true,
          activeMenu: '/admin/merchant/device',
          dynamicNewTab: true,
          title: '商户水电表管理',
        },
      },
      {
        path: '/admin/merchant/shopbank',
        name: 'Shopbank',
        component: () => import('@/views/merchant/shopbank.vue'),
        meta: {
          title: '商户结算账号信息',
          icon: 'home-2-line',
        },
      },
      {
        id: '1550811117264179200',
        path: '/admin/merchant/wallet',
        name: 'Wallet',
        component: () => import('@/views/merchant/wallet.vue'),
        meta: {
          title: '商户钱包',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/merchant/shopuser',
        name: 'Shopuser',
        component: () => import('@/views/merchant/shopuser.vue'),
        meta: {
          title: '商户用户账号',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/merchant/shopList',
        name: 'Shoplist',
        component: () => import('@/views/merchant/shopList.vue'),
        meta: {
          title: '商户列表',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/merchant/shopApplyList',
        name: 'ShopApplylist',
        component: () => import('@/views/merchant/shopApplyList.vue'),
        meta: {
          title: '商户申请列表',
          icon: 'home-2-line',
        },
      },
      {
        id: '1552527822218727424',
        path: '/admin/merchant/detail',
        name: 'MerchantDeatail',
        component: () => import('@/views/merchant/detail.vue'),
        meta: {
          hidden: true,
          title: '商户详情',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/merchant/dispose',
        name: 'MerchantDispose',
        component: () => import('@/views/merchant/dispose.vue'),
        meta: {
          hidden: true,
          title: '商户配置',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/contract/templete',
        name: 'MerchantContractTemplete',
        component: () => import('@/views/contract/contractTemplete/index.vue'),
        meta: {
          title: '合同模板',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/contract/recharge',
        name: 'MerchantContractRecharge',
        component: () => import('@/views/contract/contractRecharge/index.vue'),
        meta: {
          title: '合同充值套餐管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/contract/use-record',
        name: 'MerchantContractRecharge',
        component: () => import('@/views/contract/contractUseRecord/index.vue'),
        meta: {
          title: '合同使用记录',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/admin/bill',
    name: 'Bill',
    component: Layout,
    sort: 4,
    meta: {
      title: '账单管理',
      icon: 'money-cny-circle-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/bill/subject',
        name: 'BillSubject',
        component: () => import('@/views/bill/subject.vue'),
        meta: {
          title: '账单科目',
          icon: 'home-2-line',
          sort: 48,
        },
      },
      {
        path: '/admin/bill/list',
        name: 'BillList',
        component: () => import('@/views/bill/list.vue'),
        meta: {
          title: '账单列表',
          icon: 'home-2-line',
          sort: 42,
        },
      },
      {
        path: '/admin/bill/detail',
        name: 'BillDetail',
        component: () => import('@/views/bill/detail.vue'),
        meta: {
          hidden: true,
          title: '账单详情',
          icon: 'home-2-line',
          sort: 48,
        },
      },
      {
        path: '/admin/bill/pushrecord',
        name: 'BillSheet',
        component: () => import('@/views/bill/pushrecord.vue'),
        // sort: 43,
        meta: {
          title: '账单推送记录',
          icon: 'home-2-line',
          sort: 44,
        },
      },
    ],
  },
  {
    path: '/smart/device',
    name: 'Device',
    component: Layout,
    sort: 5,
    meta: {
      title: '设备管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/smart/device/shipment',
        name: 'DeviceShipment',
        component: () => import('@/views/device/shipmentManagement'),
        meta: {
          title: '出货记录',
          icon: 'barcode-line',
        },
      },
      {
        path: '/smart/device/shipment/add',
        name: 'DeviceShipmentAdd',
        meta: {
          hidden: true,
          activeMenu: '/smart/device/shipment',
          dynamicNewTab: true,
          title: '添加出货记录',
        },
        component: () =>
          import('@/views/device/shipmentManagement/pages/Add.vue'),
      },
      {
        path: '/smart/device/shipment/edit',
        name: 'DeviceShipmentEdit',
        meta: {
          hidden: true,
          activeMenu: '/smart/device/shipment',
          dynamicNewTab: true,
          title: '编辑出货记录',
        },
        component: () =>
          import('@/views/device/shipmentManagement/pages/TableEdit.vue'),
      },
      {
        path: '/smart/device/shipment/detail',
        name: 'DeviceShipmentDetail',
        meta: {
          hidden: true,
          activeMenu: '/smart/device/shipment',
          dynamicNewTab: true,
          title: '出货记录详情',
        },
        component: () =>
          import('@/views/device/shipmentManagement/pages/Detail.vue'),
      },
      {
        path: '/smart/device/type',
        name: 'DeviceType',
        component: () => import('@/views/device/typeManagement'),
        meta: {
          title: '设备类型',
          icon: 'cpu-line',
        },
      },
      {
        path: '/smart/device/model',
        name: 'DeviceModel',
        component: () => import('@/views/device/modelManagement'),
        meta: {
          title: '设备型号',
          icon: 'copyright-fill',
        },
      },

      {
        path: '/smart/device/brand',
        name: 'DeviceBrand',
        component: () => import('@/views/device/brandManagement'),
        meta: {
          title: '设备品牌',
          icon: 'album-line',
        },
      },

      {
        path: '/smart/device/member-bindlog',
        name: 'MemberBindlog',
        component: () => import('@/views/device/memberBindlog'),
        meta: {
          title: '操作员设备绑定记录',
          icon: 'album-line',
        },
      },
    ],
  },
  {
    path: '/admin/financial',
    name: 'financial',
    component: Layout,
    sort: 6,
    meta: {
      title: '财务管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/financial/refund',
        name: 'Refund',
        component: () => import('@/views/financial/refund.vue'),
        meta: {
          title: '退款记录',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/financial/invoice/management',
        name: 'InvoiceManagement',
        component: () => import('@/views/financial/invoiceManagement.vue'),
        meta: {
          title: '发票管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/financial/invoice/management/bill/:id',
        name: 'ManagementBill',
        component: () => import('@/views/financial/components/bill.vue'),
        meta: {
          hidden: true,
          title: '发票详情',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/financial/preferential',
        name: 'Preferential',
        component: () => import('@/views/financial/preferential.vue'),
        meta: {
          title: '平台充值优惠配置',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/log',
    name: 'log',
    component: Layout,
    sort: 7,
    meta: {
      title: '日志管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/log/sendMsg',
        name: 'SendMsgLog',
        component: () => import('@/views/log/sendMsgLog'),
        meta: {
          title: '短信发送记录日志',
          icon: 'home-2-line',
        },
      },
      {
        path: '/log/wxPushMsg',
        name: 'WxPushMsgLog',
        component: () => import('@/views/log/wxPushMsgLog'),
        meta: {
          title: '微信推送记录',
          icon: 'home-2-line',
        },
      },
      {
        path: '/log/member/visit',
        name: 'MemberVisitLog',
        component: () => import('@/views/log/memberVisitLog'),
        meta: {
          title: '会员访问记录',
          icon: 'home-2-line',
        },
      },
      {
        path: '/log/shopuser/login',
        name: 'ShopUserLoginLog',
        component: () => import('@/views/log/shopUserLoginLog'),
        meta: {
          title: '商户用户登录日志',
          icon: 'home-2-line',
        },
      },
      {
        path: '/log/shop/action',
        name: 'ShopActionLog',
        component: () => import('@/views/log/shopActionLog'),
        meta: {
          title: '商户操作日志',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/admin/basicssetting',
    name: 'basicssetting',
    component: Layout,
    sort: 8,
    meta: {
      title: '基础设置',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/basicssetting/banners',
        name: 'Banners',
        component: () => import('@/views/basicssetting/banners'),
        meta: {
          title: '轮播图',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/code/poster/management',
        name: 'CodePosterManagement',
        component: () => import('@/views/basicssetting/codePosterManagement'),
        meta: {
          title: '二维码海报管理',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/wxshare',
        name: 'Wxshare',
        component: () => import('@/views/basicssetting/wxshare'),
        meta: {
          title: '微信分享设置',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/charge',
        name: 'Charge',
        component: () => import('@/views/basicssetting/charge'),
        meta: {
          title: '收费标准',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/bill',
        name: 'BasicssettingBill',
        component: () => import('@/views/basicssetting/bill'),
        meta: {
          title: '账单均摊设置',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/cost',
        name: 'BasicssettingCost',
        component: () => import('@/views/basicssetting/cost.vue'),
        meta: {
          title: '商户成本费用配置',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/rate',
        name: 'BasicssettingRate',
        component: () => import('@/views/basicssetting/rate.vue'),
        meta: {
          title: '商户业务费率设置',
          icon: 'home-2-line',
        },
      },
      {
        path: '/admin/basicssetting/paramsSetting',
        name: 'ParamsSetting',
        component: () => import('@/views/basicssetting/paramsSetting'),
        meta: {
          title: '商户参数设置',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    id: '1550864587123986432',
    path: '/admin/member',
    name: 'member',
    component: Layout,
    sort: 9,
    meta: {
      title: '用户管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        id: '1550864951487369216',
        path: '/admin/member/info',
        name: 'Member',
        component: () => import('@/views/member/member'),
        meta: {
          title: '会员管理模块',
          icon: 'home-2-line',
        },
      },
      {
        id: '1550865122430423040',
        path: '/admin/member/service/order',
        name: 'MemberServiceOrder',
        component: () => import('@/views/member/memberServiceOrder'),
        meta: {
          title: '会员订单信息模块',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    path: '/admin/distributor',
    name: 'Distributor',
    component: Layout,
    sort: 3,
    meta: {
      title: '渠道商',
      icon: 'bill-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/admin/distributor/manage',
        name: 'DistributorManage',
        meta: {
          title: '渠道商管理',
          icon: 'group-line',
        },
        component: () => import('@/views/distributor/manage/list/index.vue'),
      },
      {
        path: '/admin/distributor/manage/apply',
        name: 'DistributorManageApply',
        meta: {
          title: '渠道商申请列表',
          icon: 'list-ordered',
        },
        component: () => import('@/views/distributor/manage/apply/index.vue'),
      },
      {
        path: '/admin/distributor/setting',
        name: 'DistributorSettingReturn',
        meta: {
          title: '渠道商设置',
          icon: 'settings-line',
        },
        component: () => import('@/views/distributor/setting/return.vue'),
      },
      {
        path: '/admin/distributor/finance',
        name: 'DistributorFinance',
        meta: {
          title: '渠道商财务',
          icon: 'bill-line',
        },
        children: [
          {
            path: '/admin/distributor/finance/commission',
            name: 'DistributorFinanceCommission',
            meta: {
              title: '分佣明细',
            },
            component: () =>
              import('@/views/distributor/finance/commission/index.vue'),
          },
          {
            path: '/admin/distributor/finance/bill',
            name: 'DistributorFinanceBill',
            meta: {
              title: '月结账单',
            },
            component: () =>
              import('@/views/distributor/finance/bill/index.vue'),
          },
        ],
      },
      {
        path: '/admin/distributor/manage/info',
        name: 'DistributorManageInfo',
        meta: {
          title: '渠道商详情',
          icon: 'group-line',
          hidden: true,
        },
        component: () => import('@/views/distributor/manage/list/info.vue'),
      },
      {
        path: '/admin/distributor/manage/second/info',
        name: 'DistributorManageSecondInfo',
        meta: {
          title: '渠道商详情',
          icon: 'group-line',
          hidden: true,
        },
        component: () => import('@/views/distributor/manage/list/info.vue'),
      },
      {
        path: '/admin/distributor/merchant/info',
        name: 'DistributorMerchantDeatail',
        component: () => import('@/views/merchant/detail.vue'), // 借用下商户详情
        meta: {
          hidden: true,
          title: '商户详情', //todo
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    id: '1551929026988347392',
    path: '/article',
    name: 'article',
    component: Layout,
    sort: 10,
    meta: {
      title: '资讯管理',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        id: '1551929187705688064',
        path: '/article/category',
        name: 'ArticleCategory',
        component: () => import('@/views/article/articlecategory'),
        meta: {
          title: '文章分类',
          icon: 'home-2-line',
        },
      },
      {
        id: '1551929443826667520',
        path: '/article/info',
        name: 'Article',
        component: () => import('@/views/article/article'),
        meta: {
          title: '文章',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    id: '1551929026988347393',
    path: '/helpcenter',
    name: 'helpcenter',
    component: Layout,
    sort: 11,
    meta: {
      title: '帮助中心',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        id: '1551929187705688065',
        path: '/helpcenter/information',
        name: 'HelpCenterInformation',
        component: () => import('@/views/helpCenter/information'),
        meta: {
          title: '帮助信息',
          icon: 'home-2-line',
        },
      },
      {
        id: '1551929443826667520',
        path: '/helpcenter/classify',
        name: 'HelpCenterClassify',
        component: () => import('@/views/helpCenter/classify'),
        meta: {
          title: '帮助分类',
          icon: 'home-2-line',
        },
      },
      {
        id: '1551929443826667520',
        path: '/helpcenter/feedback',
        name: 'HelpCenterFeedback',
        component: () => import('@/views/helpCenter/feedback'),
        meta: {
          title: '帮助反馈',
          icon: 'home-2-line',
        },
      },
      {
        id: '15525278222187274242131',
        path: '/helpcenter/informationOperation',
        name: 'InformationOperation',
        component: () => import('@/views/helpCenter/informationOperation.vue'),
        meta: {
          hidden: true,
          title: '帮助信息操作',
          icon: 'home-2-line',
        },
      },
    ],
  },
  {
    id: '10086',
    path: '/houseTag',
    name: 'tag',
    component: Layout,
    sort: 2,
    meta: {
      title: '标签',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/houseTag/index',
        name: 'HouseTagIndex',
        component: () => import('@/views/houseTag/index.vue'),
        meta: {
          title: '房源列表',
          hidden: true,
          icon: 'shield-user-line',
        },
      },
      {
        path: '/houseTag/tag',
        name: 'HouseTagTag',
        component: () => import('@/views/houseTag/tag.vue'),
        meta: {
          title: '房源标签',
          icon: 'shield-user-line',
        },
      },
    ],
  },
  {
    id: '10087',
    path: '/houseAudit',
    name: 'audit',
    component: Layout,
    sort: 2,
    meta: {
      title: '上架',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/houseAudit/index',
        name: 'HouseAuditIndex',
        component: () => import('@/views/houseAudit/index.vue'),
        meta: {
          title: '房屋审核',
          icon: 'shield-user-line',
        },
      },
    ],
  },
  {
    id: '10088',
    path: '/houseAllocation',
    name: 'allocation',
    component: Layout,
    sort: 2,
    meta: {
      title: '房源配置',
      icon: 'apps-line',
      breadcrumbHidden: true,
    },
    children: [
      {
        path: '/houseAllocation/index',
        name: 'HouseAllocationIndex',
        component: () => import('@/views/houseAllocation/index.vue'),
        meta: {
          title: '房源配置',
          icon: 'shield-user-line',
        },
      },
    ],
  },
  // {
  //   path: '*',
  //   redirect: '/404',
  //   meta: {
  //     hidden: true,
  //   },
  // },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
