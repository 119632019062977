/**
 * 公共方法封装
 * @author iron.guo 郭成杰
 * @since 2022-07-23
 */
export const CommonMixin = {
  data() {
    return {}
  },
  created() {
    console.log(' -- commonmixin created -- ')
  },
  computed: {},
  methods: {
    preventAll(e) {
      var keynum = window.event ? e.keyCode : e.which //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning('禁止输入小数以及负数')
        e.target.value = ''
      }
    },
    preventNeg(e) {
      var keynum = window.event ? e.keyCode : e.which //获取键盘码
      if (keynum == 189 || keynum == 109) {
        this.$message.warning('禁止输入负数')
        e.target.value = ''
      }
    },
    preventRad(e) {
      var keynum = window.event ? e.keyCode : e.which //获取键盘码
      if (keynum == 190 || keynum == 110) {
        this.$message.warning('禁止输入小数')
        e.target.value = ''
      }
    },
  },
}
