export function loadMap() {
  return new Promise((resolve) => {
    if (window.TMap) {
      resolve(window.TMap)
      return
    }

    window.tmapCallback = () => {
      resolve(window.TMap)
    }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://map.qq.com/api/gljs?v=1.exp&key=NMIBZ-UFAWG-BZ7Q3-I74ER-6DQCO-IEF6V&libraries=${[
      'service',
    ].join(
      ','
      // script.src = `https://map.qq.com/api/gljs?v=1.exp&key=TKSBZ-2YFEX-VLW43-TALMA-5E647-T7FUL&libraries=${['service'].join(
      //   ',',
    )}&callback=tmapCallback`
    document.body.appendChild(script)
  })
}
