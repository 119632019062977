import Cookies from 'js-cookie'

const inFifteenMinutes = new Date(new Date().getTime() + 12 * 60 * 60 * 1000)

const TokenKey = 'accessToken'

export function setToken(accessToken) {
  // console.log(accessToken)
  return Cookies.set(TokenKey, accessToken, { expires: inFifteenMinutes })
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
