import createEnum from './createEnum.js'
export const CONTRACTTEMPLETETYPE = createEnum([
  {
    label: '普通住房',
    value: '0',
  },
  {
    label: '商铺',
    value: '1',
  },
  {
    label: '公寓',
    value: '2',
  },
  {
    label: '厂房',
    value: '3',
  },
])

export const ESignContractStatus = createEnum([
  {
    label: '待生效',
    value: '5',
    badge: 'processing',
  },
  {
    label: '生效中',
    value: '1',
    badge: 'processing',
  },
  {
    label: '退租中',
    value: '3',
    badge: 'warning',
  },
  {
    label: '已完结',
    value: '0',
    badge: 'normal',
  },
  {
    label: '已过期',
    value: '2',
    badge: 'normal',
  },
  {
    label: '待签署',
    value: '6',
    badge: 'warning',
  },
  {
    label: '已撤销',
    value: '8',
    badge: 'normal',
  },
])
