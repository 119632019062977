/**
 * @description 路由拦截状态管理，目前两种模式：all模式与intelligence模式，其中partialRoutes是菜单暂未使用
 */
import { asyncRoutes, constantRoutes, resetRouter } from '@/router'
// import { convertRouter, filterRoutes } from '@/utils/routes'
import { filterAsyncRoutes, filterRoutes } from '@/utils/routes'
// import { menuListTree } from '@/api/system/menu'
import { getUserInfo } from '@/api/system/user'

const state = () => ({
  routes: [],
  activeName: '',
  menuList: [],
})
const getters = {
  routes: (state) => state.routes,
  activeName: (state) => state.activeName,
  menuList: (state) => state.menuList,
}
const mutations = {
  /**
   * @description 多模式设置路由
   * @param {*} state
   * @param {*} routes
   */
  setRoutes(state, routes) {
    console.log(routes, 'routesroutesroutes')
    state.routes = routes
  },
  /**
   * @description 修改Meta
   * @param {*} state
   * @param options
   */
  changeMenuMeta(state, options) {
    function handleRoutes(routes) {
      return routes.map((route) => {
        if (route.name === options.name) Object.assign(route.meta, options.meta)
        if (route.children && route.children.length)
          route.children = handleRoutes(route.children)
        return route
      })
    }
    state.routes = handleRoutes(state.routes)
  },
  /**
   * @description 修改 activeName
   * @param {*} state
   * @param activeName 当前激活菜单
   */
  changeActiveName(state, activeName) {
    state.activeName = activeName
  },
  getMenuList(state, menuList) {
    state.menuList = menuList
  },
}

// 递归路由改造
function changeTreeList(asyncRoutes) {
  let routeList = []
  asyncRoutes.map((menu) => {
    routeList.push({
      ...menu,
    })
    if (menu.children && menu.children.length >= 1) {
      routeList = routeList.concat(changeTreeList(menu.children))
    }
  })
  return routeList
}

function deleteChildren(routes) {
  if (routes.length) {
    for (let i in routes) {
      if (routes[i].children && routes[i].children.length) {
        deleteChildren(routes[i].children)
      } else {
        delete routes[i].children
      }
    }
  }
  return routes
}

// 根据登录用户获取菜单
const actions = {
  getMenuList({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo({})
        .then((res1) => {
          sessionStorage.setItem('username', res1.data.username)
          res1.data.menus.forEach((item) => {
            if (item.path === '/admin/house') {
              item.children.push({
                actionPath: '/admin/house/contract/detail',
                activeMenu: '/admin/house/contract',
                children: null,
                icon: null,
                id: '',
                name: '合同详情',
                path: '/admin/house/contract/detail',
                pid: '',
                type: 1,
              })
            }
            if (item.pa) console.log('item', item)
            if (item.path === '/admin/distributor') {
              item.children.push({
                name: '渠道商详情',
                path: '/admin/distributor/manage/info',
                type: 1,
              })
              item.children.push({
                name: '渠道商详情',
                path: '/admin/distributor/manage/second/info',
                type: 1,
              })
              item.children.push({
                name: '商户详情',
                path: '/admin/distributor/merchant/info',
                type: 1,
              })
            }
          })
          commit('getMenuList', res1.data.menus)
          resolve(res1.data.menus)
        })
        .catch((error) => {
          reject(error)
        })
      // menuListTree({})
      //   .then((res) => {
      //     commit('getMenuList', res.data)
      //     resolve(res.data)
      //   })
      //   .catch((error) => {
      //     reject(error)
      //   })
    })
  },

  /**
   * @description 多模式设置路由
   * @param {*} { commit }
   * @param mode
   * @returns
   */
  async setRoutes({ commit }, menuList = []) {
    // 默认前端路由
    let routes = [...asyncRoutes]
    // routes = convertRouter(menuList) // (后端递归过滤,勿删)
    routes = filterAsyncRoutes(menuList, changeTreeList(routes)) // (前端递归过滤,勿删)
    routes = deleteChildren(routes)
    // console.log(routes)
    // 根据权限和rolesControl过滤路由
    const accessRoutes = filterRoutes([...constantRoutes, ...routes], true)
    // 按照sort排序
    // accessRoutes.sort((a, b) => {
    //   return a.sort ? a.sort - b.sort : -1
    // })

    // 设置菜单所需路由
    commit('setRoutes', JSON.parse(JSON.stringify(accessRoutes)))
    // 根据可访问路由重置Vue Router
    await resetRouter(accessRoutes)
  },
  /**
   * @description 修改Route Meta
   * @param {*} { commit }
   * @param options
   */
  changeMenuMeta({ commit }, options = {}) {
    commit('changeMenuMeta', options)
  },
  /**
   * @description 修改 activeName
   * @param {*} { commit }
   * @param activeName 当前激活菜单
   */
  changeActiveName({ commit }, activeName) {
    commit('changeActiveName', activeName)
  },
}
export default { state, getters, mutations, actions }
