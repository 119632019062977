/**
 * 房源状态
 */
export const HouseSourceStatus = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '在租中',
    value: '3',
  },
  {
    label: '空房可租',
    value: '1',
  },
  {
    label: '空房不可租',
    value: '2',
  },
]

export const MeterSubject = {
  /**
   * 电表subjectid
   */
  electric: '3001',
  /**
   * 水表subjectid
   */
  water: '3002',
}
/**
 * 设备绑定
 */
export const DeviceBindStatus = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '未绑定',
    value: '0',
  },
  {
    label: '已绑定',
    value: '1',
  },
]

/**
 * 电表状态
 */
export const ElecticStatus = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '通电',
    value: '1',
  },
  {
    label: '断电',
    value: '0',
  },
]

/**
 * 水表状态
 */
export const WaterStatus = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '通水',
    value: '0',
  },
  {
    label: '断水',
    value: '1',
  },
]

/**
 * 抄表方式
 */
export const MeterMethod = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '手动录入',
    value: '0',
  },
  {
    label: '自动获取',
    value: '1',
  },
]
/**
 * 抄表原因
 */
export const MeterReasons = [
  {
    label: '全部',
    value: 'all',
  },
  {
    label: '账单结算',
    value: '1',
  },
  {
    label: '绑定新表',
    value: '2',
  },
  {
    label: '更换旧表',
    value: '3',
  },
  {
    label: '租客退租',
    value: '4',
  },
  {
    label: '水电抄表',
    value: '5',
  },
  {
    label: '租客入住',
    value: '6',
  },
  {
    label: '水电抄表',
    value: '7',
  },
]

/**
 * 费用科目subjectId
 */
export const BILLSUBJECTTYPE = {
  /**
   * 租金
   */
  rental: '1001',
  /**
   * 押金
   */
  deposit: '2001',
  /**
   * 电费
   */
  electricity: '3001',
  /**
   * 水费
   */
  water: '3002',
  /**
   * 用电管理费
   */
  electricManagement: '3003',
  /**
   * 线路维护费
   */
  lineManagement: '3004',
  /**
   * 用水管理费
   */
  waterManagement: '3005',
  /**
   * 管道维护费
   */
  pipeManagement: '3006',
  /**
   * 物业费
   */
  property: '4001',
}
